
.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.custom-shadow-1 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.custom-shadow-2 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.custom-shadow-3 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.custom-shadow-4 {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    -webkit-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.raise-1 {
    z-index: 1;
}

.raise-2 {
    z-index: 2;
}

.raise-3 {
    z-index: 3;
}
 
.raise-4 {
    z-index: 4;
}

.lower-1 {
    z-index: -1;
}

.lower-1 {
    z-index: -2;
}

.lower-3 {
    z-index: -3;
}

.lower-4 {
    z-index: -4;
}

.employment-circle {

    .logo-circle {
        border: solid 3px white;


        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        img {
            border-radius: 100%;
        }
    }

    .employment-circle-inner {
        border: solid 3px white;
        background-color: $green;
        padding-top: 5px;
        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        &.employment-circle-inner-green {
            background-color: rgb(77, 179, 106);
        }
        &.employment-circle-inner-yellow {
            background-color: rgb(235, 174, 17);
        }
        &.employment-circle-inner-orange {
            background-color: rgb(235, 121, 17);
        }
        &.employment-circle-inner-pink {
            background-color: rgb(224, 114, 114);
        }
        &.employment-circle-inner-blue {
            background-color: rgb(88, 155, 255);
        }
        &.employment-circle-inner-purple {
            background-color: rgb(131, 88, 255);
        }

    }

}

#archk-search-modal {
    .card {
        margin-bottom: 0;
        box-shadow: none !important;

        .card-header {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .card-footer {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .archk-dashboard-box {
        border: solid 1px $gray-100 !important;
    }
}

@include media-breakpoint-up(lg) {

    .vertical-timeline {
        padding: 0;
        margin-bottom: 30px;
    }

    .vertical-timeline-element {
        margin-top: 0px;
        margin-bottom: 15px;

        p {
            margin-top: 0px;
        }

        .vertical-timeline-element-date {
            padding-top: 5px;
        }
    }

    .vertical-timeline-element-content {
        padding: .5rem .75rem;
    }
}

.border-none {
    border: none !important;
}

.hover-info {
    &:hover {
        color: $info;
    }
}
.hover-success {
    &:hover {
        color: $success;
    }
}
.hover-warning {
    &:hover {
        color: $warning;
    }
}
.hover-danger {
    &:hover {
        color: $danger;
    }
}

.avatar-image {
    border-radius: 100%;
}

.error {
    background-color: $green !important;
}

.archk-react-tooltip {
    z-index: 999999;

    &[class*="error"] {
        background-color: $red !important;
    }
    &[class*="info"] {
        background-color: $info !important;
    }
    &[class*="success"] {
        background-color: $success !important;
    }
    &[class*="warning"] {
        background-color: $warning !important;
    }


// .success {
//     background-color: var(--rt-color-success);
//     color: var(--rt-color-white);
//   }
  
//   .warning {
//     background-color: var(--rt-color-warning);
//     color: var(--rt-color-white);
//   }
  
//   &.error {
//     background-color: $danger;
//   }
  
//   .info {
//     background-color: var(--rt-color-info);
//     color: var(--rt-color-white);
//   }

}

.archk-case-alerts {

    .archk-case-alerts-background {
        position: fixed;
        top: 0;
        right: 0;
        left: 200px;
        bottom: 0;
        background: rgba(0,0,0,.7);
        z-index: 1000;
    }

    .archk-case-alerts-main {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 400px;
        border-left: solid 1px $gray-700;
        background-color: white;
        z-index: 1040;
        overflow: auto;
    }

}

.archk-unavailable-circle {
    color: $white;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom:  2px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 100%;
    background-color: lighten($red, 15);
    border: solid 2px $red;
    display: inline-block;
}

.archk-available-circle {
    color: $white;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom:  2px;
    padding-left: 6px;
    padding-right: 6px;

    border-radius: 100%;
    background-color: lighten($green, 15);
    border: solid 2px $green;
    display: inline-block;
}
.archk-on-call-circle  {
    color: $white;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom:  3px;
    padding-left: 6px;
    padding-right: 6px;

    border-radius: 100%;
    background-color: lighten($warning, 15);
    border: solid 2px $warning;
    display: inline-block;
}

.pulse {
    color: $green;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
    border-radius: 50%;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.modal {
    .modal-d-none {
        display: none;
    }
}

.archk-email-body {

    max-width: 100%;

    body {
        min-height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    img {
        max-width: 100% !important;
    }
}

a:link {
    text-decoration: none !important;
}


.keypad {

    position: fixed;
    text-align: center;

    width: 100%;
    left: 0;
    right: 0;
    top: 150px;

    z-index: 2000;

    .keypad-background {

        background: rgba(0,0,0,.5);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;

    }

    .keypad-content {

        width: 300px;
        background: white;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        margin: auto;
        position: relative;
        z-index: 2;

        .backspace {

            i {
                position: relative;
                top: 8px;
            }

        }

        .btn {
            font-size: 1.5rem;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .header {
            font-size: 1.5rem;
            border-bottom: solid 1px $gray-400;
            padding: $spacer 0;
        }

        .block {
            font-size: 1.75rem;
            width: 33.3%;
            display: inline-block;
            border-bottom: solid 1px $gray-400;
            padding-top: 10px;

            transition: all 0.3s;
            &:hover {
                background-color: $gray-200;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                cursor: pointer;
            }

            &.last {
                border-bottom: 0;
            }

            &.third.last {
                border-bottom-right-radius: 20px;

            }
            &.first.last {
                border-bottom-left-radius: 20px;

            }

            &.first {
                border-right: solid 1px $gray-400;
            }

            &.second {
                border-right: solid 1px $gray-400;
            }

            .letters {
                font-size: $font-size-base;
                display: block;
                font-weight: normal;
                line-height: 10px;
                padding-bottom: 16px;
            }

            .placeholder {
                color: white;
            }

        }

    }

}

.html-p-small {
    p {
        font-size: 12px !important;
        margin-bottom: 0px;
    }

    body {
        min-height: 0px !important;
    }

}

.archk-user-comments {
    .quill {
        .ql-container {
            .ql-editor {
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 18px;
                padding-right: 18px;

                &::before {
                    padding-top: 10px;
                    padding-left: 15px;
                }
            }
        }
    }
    
}

.archk-timeline-feed {
    img {
        max-width: 100%;
    }
}

.comment-container {
    p {
        font-size: $font-size-base;

        margin-bottom: 0px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.archk-user-icons {

    $offset: 8px;

    .archk-user-icon {
        border-radius: 100%;
        overflow: hidden;
    }

    .archk-user-icon:nth-child(2) {
        
        position: relative;
        left: $offset * -1;
    }
    .archk-user-icon:nth-child(3) {
        
        position: relative;
        left: $offset * -2;
    }
    .archk-user-icon:nth-child(4) {
        
        position: relative;
        left: $offset * -3;
    }
    .archk-user-icon:nth-child(5) {
        
        position: relative;
        left: $offset * -4;
    }

    .name-circle {
        outline: solid 1px $gray-300;
        // padding-top: 0;

    }

    img {
        outline: solid 1px $gray-300;

        width: 35px;
        height: 35px;
        border-radius: 100%;

    }

}

.vertical-timeline-element-icon {
    text-align: center;
    i {
        font-size: 30px;
        padding-top: 15px;
    }
}