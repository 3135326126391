// <div className="archk-pipeline">
//                 <Row>

//                     <Col style={{maxWidth: 250}} className="archk-pipeline-col">
//                         <div className="archk-pipeline-col-wrapper bg-secondary">
//                             <div className="archk-pipeline-col-inner">
//                                 <Card>
//                                     <CardBody className="archk-pipeline-card">
//                                         <p>Architeck LLC</p>
//                                     </CardBody>
//                                 </Card>
//                             </div>
//                         </div>
//                     </Col>

//                 </Row>
//             </div>

.archk-pipeline {
    padding-bottom: $spacer ;

    .archk-pipeline-row {
        white-space: pre-line;
        overflow-x: auto;
        white-space: nowrap;
        display: block;

    }

    .archk-pipeline-col {
    
        padding-right: 0;
        padding-left: $spacer * 2;
        display: inline-block;
        float: none;
        width: 300px;
        vertical-align: middle;
        
        &:first-child {
            padding-left: $spacer;
        }
    }

      .archk-pipeline-outer {
        border-bottom: solid 1px $gray-200;
        // border-bottom-left-radius: 6px;
        // border-bottom-right-radius: 6px;
       
    }
    .archk-pipeline-col-wrapper {

        height: calc(100vh - 235px);
        overflow: auto;
        padding-left: $spacer;
        padding-right: $spacer;
        // margin-bottom: $spacer;
        border-bottom: solid 1px $gray-300 !important;
        border-top: solid 1px $gray-300 !important;
        // border-left: solid 1px $gray-300 !important;
        background-color: darken($gray-100, 5%);

    }

  

    .archk-pipeline-card {
        box-shadow: none !important;

        &.border-danger, &.border-info {
            border-width: 2px;
            border-radius: 6px;

            .card-footer:last-child {
                border-bottom-left-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }
           
        }

        border-radius: 6px;

        .card-header {
            border-top-left-radius: 6px !important;
            border-top-right-radius: 6px !important;
        }
        .card-footer:last-child {
            border-bottom-left-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }

        .card-header, .card-footer, .card-body {
            padding-top: $spacer * .5;
            padding-bottom: $spacer * .5;
        }
    }

}